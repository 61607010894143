import {createContext, useCallback, useEffect, useMemo, useState} from 'react';
import {useSnackbar} from "notistack";
import {postOrganizationFaq, updateOrganization, updateOrganizationFaq, updateUser} from "../api";
import useCurrentUser from "../hooks/useCurrentUser";
//import useSummary from "../hooks/useSummary";
import {FAQ_LIMIT_LABEL, pricingData} from "../constants/constants";
import {useLocation, useNavigate} from "react-router-dom";
import {useMediaQuery, useTheme} from "@mui/material";
import {extractCountryCode, hexToHsl, removeCountryCode, removeHttps} from "../helpers/helpers";
import useOrganizationFaqs from "../hooks/useOrganizationFaqs";
import {
   AlternateEmail,
   Business,
   Email,
   Face, Facebook,
   HowToReg,
   Http,
   Instagram,
   LinkedIn,
   Twitter,
   YouTube
} from "@mui/icons-material";
import * as React from "react";
import TikTokIcon from "../assets/icons/TikTokIcon";
import SnapchatIcon from "../assets/icons/SnapchatIcon";
import DiscordIcon from "../assets/icons/DiscordIcon";
import StackOverflowIcon from "../assets/icons/StackOverflowIcon";
import RumbleIcon from "../assets/icons/RumbleIcon";
import KickIcon from "../assets/icons/KickIcon";
import KickIconAlt from "../assets/icons/KickIconAlt";

export const DemoContext = createContext({});
export const DemoContextProvider = ({ children, props }) => {
   const { enqueueSnackbar } = useSnackbar()
   const navigate = useNavigate();
   const production = useMemo(()=>window.location.hostname === 'emailsignature.app',[]);
   const theme = useTheme();
   const matchSM = useMediaQuery(theme.breakpoints.down('sm'));
   const matchMD = useMediaQuery(theme.breakpoints.down('md'));
   
   const [chatOpen, setChatOpen] = useState(true); // used for demo purposes during customization
   const [convoMode, setConvoMode] = useState(false); // used for demo purposes during customization
   
   const [poweredBy, setPoweredBy] = useState(true);
   const [showTeam, setShowTeam] = useState(true);
   const [showFAQ, setShowFAQ] = useState(true);
   const [hideClose, setHideClose] = useState(false);
   const [buttonGradient, setButtonGradient] = useState(true);
   const [autoPrompt, setAutoPrompt] = useState(false);
   const [autoSlack, setAutoSlack] = useState(false);
   const [collectEmail, setCollectEmail] = useState(false);
   const [collectPhone, setCollectPhone] = useState(false);
   
   const [companyTheme, setCompanyTheme] = useState('auto');
   const [primaryColor, setPrimaryColor] = useState(localStorage.getItem('signature_primary_color') || '#39C091');
   const [secondaryColor, setSecondaryColor] = useState(localStorage.getItem('signature_secondary_color') || '#8259C3');
   const [tertiaryColor, setTertiaryColor] = useState(localStorage.getItem('signature_tertiary_color') || '#9E9EA8');
   const [quaternaryColor, setQuaternaryColor] = useState(localStorage.getItem('signature_quaternary_color') || '#888');
   const [chatPosition, setChatPosition] = useState('right');
   const [companyAIName, setCompanyAIName] = useState('');
   const [companyAIPersonality, setCompanyAIPersonality] = useState('Professional, Formal, and Instructive');
   const [companyName, setCompanyName] = useState('');
   const [companyDesc, setCompanyDesc] = useState('');
   const [companyStatus, setCompanyStatus] = useState('');
   const [companyWebhook, setCompanyWebhook] = useState('');
   const [companyWebsite, setCompanyWebsite] = useState('');
   const [companyPhone, setCompanyPhone] = useState('');
   const [companyEmail, setCompanyEmail] = useState('');
   const [companyEmailNotifications, setCompanyEmailNotifications] = useState(false);
   const [companyGA, setCompanyGA] = useState('');
   const [companyCountryPhone, setCompanyCountryPhone] = useState('+1');
   const [companyType, setCompanyType] = useState('');
   const [previewMode, setPreviewMode] = useState('Closed');
   const [didSetup, setDidSetup] = useState(false);
   const [isLoadingOrganization, setIsLoadingOrganization] = useState(true);
   
   const darkMode =
      false || localStorage.getItem('THEME') === 'true' ||
      (!localStorage.getItem('THEME') &&
         window.matchMedia &&
         window.matchMedia('(prefers-color-scheme: dark)').matches);
   
   const user = {}//useCurrentUser();
   const { token, userID, organizationID, isLoadingCurrentUser, isRefetchingCurrentUser,  plan: currentPlan } = user;
   //const user = useUser();
   //const isLoadingCurrentUser = useMemo(()=>user ? user.isLoadingCurrentUser : false, [user])
   //const isRefetchingCurrentUser = useMemo(()=>user ? user.isRefetchingCurrentUser : ()=>{}, [user])
   //const currentPlan = useMemo(()=>user && user.plan != undefined ? user.plan : 'Loading...', [user])
   const refetchCurrentUser = useMemo(()=>user ? user.refetchCurrentUser : ()=>{}, [user])
   const apiKey = useMemo(()=>user ? user.organizationID : '', [user])
   const organization = useMemo(()=>user ? user.organization : '', [user])
   const notificationSettings = useMemo(()=>user ? user?.user?.notification_settings : '', [user])
   const totalFAQs = useMemo(()=>user?.summary?.usage?.total_faq || 0, [user])
   
   const location = useLocation();
   const signatureId = location.pathname.split("/").pop();
   
   useEffect(()=> {
      if(notificationSettings && (notificationSettings.includes('new_live_chat_recap') || notificationSettings.includes('widget_contact_created'))){
         setCompanyEmailNotifications(true)
      }
   },[notificationSettings])
   
   const isFeatureAllowed = useCallback((featureKey) => {
      if(!userID){
         return false
      }
      if (currentPlan) {
         const planFeatures = {
            free: ['feature1'],
            starter: ['feature1'],
            basic: ['auto_forward_to_slack', 'status_message', 'email_notifications'],
            growth: ['show_powered_by', 'capture_email', 'auto_forward_to_slack', 'status_message', 'email_notifications'],
            pro: ['show_powered_by', 'capture_email', 'capture_phone', 'auto_forward_to_slack', 'webhook_endpoint', 'status_message', 'email_notifications', 'api_docs'],
         };
         return planFeatures[currentPlan] && planFeatures[currentPlan].includes(featureKey);
      }
      return false;
   },[userID, currentPlan]);
   
   // Helper function to check if adding FAQ is allowed
   const isAddingFaqAllowed = useMemo(() => {
      if (currentPlan) {
         const plan = pricingData.find(item => item.plan.toLowerCase() === currentPlan);
         if (plan) {
            // If the plan has unlimited FAQs or the total FAQs is less than the plan allows, return true
            return plan.faq === "Unlimited" || totalFAQs < plan.faq;
         }
      }
      return false;
   }, [currentPlan, totalFAQs, pricingData, isLoadingCurrentUser && !isRefetchingCurrentUser]);
   
   
   const {
      isLoading: isLoadingFAQ,
      isRefetching: isRefetchingFAQ,
      data: faqs,
      refetch: refetchFaq,
   } = useOrganizationFaqs(apiKey);
   
   
   
   const selectedFAQ = useMemo(() => {
      if(!signatureId !== undefined){
         return (
            faqs?.filter(
               item => item.id === signatureId,
            )[0]
         );
      }
   }, [faqs, signatureId]);
   
   const handleNewFAQ = useCallback(async (callback) =>{
      if (!isLoadingCurrentUser && !isRefetchingCurrentUser) {
      if (isAddingFaqAllowed) {
         const result = await postOrganizationFaq(token, userID, {question:"", status: 0});
         if (result && result.id) {
            refetchFaq().then(()=>{
               navigate(`../dashboard/signatures/${result.id}`)
            })
         }
      }else{
         enqueueSnackbar(FAQ_LIMIT_LABEL, {variant:'error'});
         return;
      }
      }
   },[refetchFaq, token, userID, isAddingFaqAllowed, isLoadingCurrentUser, isRefetchingCurrentUser, enqueueSnackbar])
   
   
   /*
   const {
      isLoading: isLoadingOrganizationSummary,
      isRefetching: isRefetchingOrganizationSummary,
      data: summary,
      refetch: refetchOrganizationSummary,
   } = useSummary();
   const {
      isLoading: isLoadingOrganization,
      data: organization,
      refetch: refetchOrganization,
   } = useOrganization();*/
   
   useEffect(()=>{
      if(organization && organization.id && !didSetup){
         setIsLoadingOrganization(false)
         //console.log('user ', user)
         //console.log('summary ', summary)
         //console.log('organization ',organization)
         setCompanyAIName(organization.ai_name)
         if(organization.ai_personality){
            setCompanyAIPersonality(organization.ai_personality)
         }
         if(organization.primary_color){
            setPrimaryColor(organization.primary_color)
         }
         if(organization.secondary_color){
            setSecondaryColor(organization.secondary_color)
         }
         if(organization.tertiary_color){
            setTertiaryColor(organization.tertiary_color)
         }
         if(organization.quaternary_color){
            setQuaternaryColor(organization.quaternary_color)
         }
         if(organization.show_powered_by){
            setPoweredBy(organization.show_powered_by)
         }
         if(organization.auto_forward_to_slack){
            setAutoSlack(organization.auto_forward_to_slack)
         }
         setCompanyEmail(organization.default_email)
         setCompanyName(organization.name)
         setCompanyDesc(organization.description)
         setCompanyWebsite(removeHttps(organization.website))
         setCompanyWebhook(removeHttps(organization.webhook_endpoint))
         setCompanyPhone(removeCountryCode(organization.phone_number))
         setCompanyCountryPhone(extractCountryCode(organization.phone_number))
         setCompanyStatus(organization.system_status)
         setDidSetup(true)
      }
   },[organization, didSetup])
  
   const updateFAQ = useCallback((id, options) =>{
         updateOrganizationFaq(token, userID, id, options).then(()=>{
            // success
            enqueueSnackbar('Updated!', {variant:'success'})
         })
      
   },[token, userID])
   
   
   const handleNotificationSave = useCallback(() => {
      const commonNotifications = ['invite_sent','new_teammate','latest_features','monthly_report','weekly_report'];
      const additionalNotifications = !companyEmailNotifications ? ['new_live_chat_recap','widget_contact_created'] : [];
      const notification_settings = [...commonNotifications, ...additionalNotifications];
      updateUser(token, userID,{
         notification_settings: notification_settings,
      }).then(() => {
         enqueueSnackbar(`Notifications turned ${!companyEmailNotifications ? 'on' : 'off'}.`, {variant:'info'});
         setCompanyEmailNotifications(!companyEmailNotifications)
         //refetchUser();
      });
   }, [companyEmailNotifications, token, userID]);
   
   
   // definitely using below
   
   
   const [latestPrompt, setLatestPrompt]  = useState(null);
   
   const [fieldsConfig, setFieldsConfig]  = useState([
      {
         key: 'full_name',
         label: 'Full Name',
         placeholder: 'Steve Jobs',
         defaultValue: '',
         tooltip: 'This is your public display name.',
         startAdornment: 'icon',
         icon: <Face/>,
         visible: true,
         type: 'text'
      },
      {
         key: 'job_title',
         label: 'Title',
         placeholder: 'CEO',
         defaultValue: '',
         tooltip: 'This is your professional title.',
         startAdornment: 'icon',
         icon: <HowToReg/>,
         visible: true,
         type: 'text'
      },
      {
         key: 'company_name',
         label: 'Company Name',
         placeholder: 'Acme Corp',
         defaultValue: '',
         tooltip: 'This is the name of your company.',
         startAdornment: 'icon',
         icon: <Business/>,
         visible: true,
         type: 'text'
      },
      {
         key: 'website',
         label: 'Website',
         placeholder: 'example.com',
         defaultValue: '',
         tooltip: 'Provide the domain to promote.',
         startAdornment: 'icon',
         icon:<Http/>,
         visible: true,
         type: 'text'
      },
      {
         key: 'phone_number',
         label: 'Phone Number',
         placeholder: '',
         defaultValue: '',
         tooltip: 'Provide a phone number to promote.',
         startAdornment: 'phone',
         visible: true,
         type: 'text'
      },
      {
         key: 'email',
         label: 'Email',
         placeholder: 'notifications@example.com',
         defaultValue: '',
         tooltip: 'Provide an email address to promote.',
         icon: <Email/>,
         startAdornment: 'icon',
         visible: true,
         type: 'text'
      },
      {
         key: 'social_username',
         label: 'Social',
         placeholder: '@ustinCameron',
         defaultValue: '',
         tooltip: 'Provide a social username to promote.',
         icon: <AlternateEmail/>,
         startAdornment: 'icon',
         visible: true,
         type: 'text'
      },
      {
         key: 'social_twitter',
         label: 'Twitter',
         placeholder: '@ustinCameron',
         defaultValue: '',
         tooltip: 'Provide a X (Twitter) username to promote.',
         icon: <Twitter/>,
         startAdornment: 'icon',
         visible: true,
         type: 'text'
      },
      {
         key: 'social_youtube',
         label: 'Youtube',
         placeholder: 'AustinCameron',
         defaultValue: '',
         tooltip: 'Provide a Youtube username to promote.',
         icon: <YouTube/>,
         startAdornment: 'icon',
         visible: true,
         type: 'text'
      },
      {
         key: 'social_linkedin',
         label: 'LinkedIn',
         placeholder: 'AustinCameron',
         defaultValue: '',
         tooltip: 'Provide a LinkedIn username to promote.',
         icon: <LinkedIn/>,
         startAdornment: 'icon',
         visible: true,
         type: 'text'
      },
      {
         key: 'social_instagram',
         label: 'Instagram',
         placeholder: '@ustinCameron',
         defaultValue: '',
         tooltip: 'Provide a Instagram username to promote.',
         icon: <Instagram/>,
         startAdornment: 'icon',
         visible: true,
         type: 'text'
      },
      {
         key: 'social_facebook',
         label: 'Facebook',
         placeholder: '@ustinCameron',
         defaultValue: '',
         tooltip: 'Provide a Facebook username to promote.',
         icon: <Facebook/>,
         startAdornment: 'icon',
         visible: true,
         type: 'text'
      },
      {
         key: 'social_tiktok',
         label: 'TikTok',
         placeholder: '@ustinCameron',
         defaultValue: '',
         tooltip: 'Provide a TikTok username to promote.',
         icon: <TikTokIcon/>,
         startAdornment: 'icon',
         visible: true,
         type: 'text'
      },
      {
         key: 'social_snapchat',
         label: 'Snapchat',
         placeholder: '@ustinCameron',
         defaultValue: '',
         tooltip: 'Provide a Snapchat username to promote.',
         icon: <SnapchatIcon/>,
         startAdornment: 'icon',
         visible: true,
         type: 'text'
      },
      {
         key: 'social_discord',
         label: 'Discord',
         placeholder: '@ustinCameron',
         defaultValue: '',
         tooltip: 'Provide a Discord username to promote.',
         icon: <DiscordIcon/>,
         startAdornment: 'icon',
         visible: true,
         type: 'text'
      },
      {
         key: 'social_stackoverflow',
         label: 'Stack Overflow',
         placeholder: '11057/ustincameron',
         defaultValue: '',
         tooltip: 'Provide a Stack Overflow username to promote.',
         icon: <StackOverflowIcon/>,
         startAdornment: 'icon',
         visible: true,
         type: 'text'
      },
      {
         key: 'social_rumble',
         label: 'Rumble',
         placeholder: '11057',
         defaultValue: '',
         tooltip: 'Provide a Rumble username to promote.',
         icon: <RumbleIcon/>,
         startAdornment: 'icon',
         visible: true,
         type: 'text'
      },
      {
         key: 'social_kick',
         label: 'Kick',
         placeholder: 'ustincameron',
         defaultValue: '',
         tooltip: 'Provide a Kick username to promote.',
         icon: <KickIconAlt/>,
         startAdornment: 'icon',
         visible: true,
         type: 'text'
      }
   ]);
   const [values, setValues] = useState(JSON.parse(localStorage.getItem('signature_fields')) || {});
   const [signatureTemplate, setSignatureTemplate] = useState(localStorage.getItem('signature_template') || 'Vertical');
   const [embedCode, setEmbedCode] = useState('');
   const [animationType, setAnimationType] = useState('None');
   const [confidentialNotice, setConfidentialNotice] = useState(localStorage.getItem('signature_notice') || null);
   const [companyLogo, setCompanyLogo] = useState("https://supportgpt.co/static/media/logo.ac7c5df8f7e3a17d768b.png");
   const [avatar, setAvatar] = useState("https://emailsignature.app/Austin_Cameron.jpg");
   const primaryStyle = { color: primaryColor };
   const secondaryStyle = { color: secondaryColor };
   const tertiaryStyle = { color: tertiaryColor };
   const quaternaryStyle = { color: quaternaryColor };
   
   const getValue = (key) => values[key] || '';
   
   const findFieldByKey = (key) => {
      const field = fieldsConfig.find((f) => f.key === key);
      return field && field.visible ? getValue(key) : null;
   };
   const handleSave = useCallback(()=>{
      localStorage.setItem('signature_fields', JSON.stringify(values))
      enqueueSnackbar('Saved!', {variant:'success'})
      
      return false
      updateOrganization(token, userID, organizationID, {
         name: companyName,
         default_email: companyEmail,
         website: (companyWebsite ? 'https://'+removeHttps(companyWebsite || '') : ''),
         webhook_endpoint: (companyWebhook ? 'https://'+removeHttps(companyWebhook || '') : ''),
         phone_number: companyCountryPhone+companyPhone,
         organization_type: companyType,
         description: companyDesc,
         google_analytics: companyGA,
         primary_color: primaryColor,
         secondary_color: secondaryColor,
         tertiary_color: tertiaryColor,
         quaternary_color: quaternaryColor,
         system_status: companyStatus,
         system_status_last_updated: companyStatus ? new Date() : null,
         ai_name: companyAIName,
         ai_personality: companyAIPersonality,
         auto_forward_to_slack: autoSlack
         // timezone: companyTimezone.offset,
         //  locale: companyLocale ? companyLocale.value : '',
      }).then(()=>{
         // success
         enqueueSnackbar('Saved!', {variant:'success'})
         refetchCurrentUser()
      })
   },[
      values,
      token, userID, organizationID,
      poweredBy,
      primaryColor,
      secondaryColor,
      tertiaryColor,
      quaternaryColor,
      companyAIName,
      companyAIPersonality,
      companyName,
      companyDesc,
      companyWebsite,
      companyPhone,
      companyEmail,
      companyGA,
      companyCountryPhone,
      companyType,
      companyStatus,
      companyWebhook,
      autoSlack,
      refetchCurrentUser
   ])
   const toggleVisibility = (key) => {
      setFieldsConfig(prevConfig => prevConfig.map(field =>
         field.key === key ? {...field, visible: !field.visible} : field
      ));
   };
   
   const socialPlatforms = [
      { platform: 'linkedin', key: 'social_linkedin', urlPattern: username => `https://www.linkedin.com/in/${username}` },
      { platform: 'stackoverflow', key: 'social_stackoverflow', urlPattern: username => `https://stackoverflow.com/users/${username}` },
      { platform: 'twitter', key: 'social_twitter', urlPattern: username => `https://twitter.com/${username}` },
      { platform: 'youtube', key: 'social_youtube', urlPattern: username => `https://youtube.com/${username}` },
      { platform: 'instagram', key: 'social_instagram', urlPattern: username => `https://instagram.com/${username}` },
      { platform: 'facebook', key: 'social_facebook', urlPattern: username => `https://facebook.com/${username}` },
      { platform: 'tiktok', key: 'social_tiktok', urlPattern: username => `https://tiktok.com/${username}` },
      { platform: 'snapchat', key: 'social_snapchat', urlPattern: username => `https://snapchat.com/${username}` },
      { platform: 'discord', key: 'social_discord', urlPattern: username => `https://discord.gg/${username}` },
      { platform: 'rumble', key: 'social_rumble', urlPattern: username => `https://rumble.com/c/${username}` },
      { platform: 'kick', key: 'social_kick', urlPattern: username => `https://kick.com/${username}` }
   ];
   
   const socialPlatformsMerged = socialPlatforms.map(platform => ({
      ...platform,
      username: findFieldByKey(platform.key),
   }));
   
   const hsl = hexToHsl(quaternaryColor);
   const hue = hsl.h;
   const saturation = hsl.s / 100;
   const lightness = hsl.l / 100
   const isColorLight = false // later
   const filter = isColorLight ?
      `brightness(0) saturate(100%) hue-rotate(${hue}deg)` :
      `invert(0.5) sepia(1) saturate(${saturation}) brightness(${lightness}) hue-rotate(${hue}deg)`;
   return (
    <DemoContext.Provider
      value={{
        actions: {
           toggleVisibility,
           setValues,
           setFieldsConfig,
           setConfidentialNotice,
           setSignatureTemplate,
           setEmbedCode,
           setAnimationType,
           getValue,
           findFieldByKey,
           
           refetchCurrentUser,
           handleNotificationSave,
           handleNewFAQ,
           isFeatureAllowed,
           isAddingFaqAllowed,
           setCompanyAIPersonality,
           setChatOpen,
           setConvoMode,
           setShowTeam,
           setShowFAQ,
           setHideClose,
           setButtonGradient,
           setAutoPrompt,
           setAutoSlack,
           setCollectEmail,
           setCollectPhone,
           setCompanyTheme,
           setPoweredBy,
           setPrimaryColor,
           setSecondaryColor,
           setTertiaryColor,
           setQuaternaryColor,
           setCompanyAIName,
           setCompanyName,
           setCompanyDesc,
           setCompanyWebsite,
           setCompanyPhone,
           setCompanyEmail,
           setCompanyEmailNotifications,
           setCompanyCountryPhone,
           setCompanyType,
           setPreviewMode,
           setCompanyStatus,
           setCompanyWebhook,
           setChatPosition,
           setCompanyGA,
           handleSave,
           updateFAQ,
           enqueueSnackbar,
           refetchFaq,
           setLatestPrompt
           //refetchOrganizationSummary
        },
        state: {
           latestPrompt,
           animationType,
           confidentialNotice,
           fieldsConfig,
           values,
           signatureTemplate,
           embedCode,
           companyLogo,
           avatar,
           primaryStyle,
           secondaryStyle,
           tertiaryStyle,
           quaternaryStyle,
           socialPlatforms,
           socialPlatformsMerged,
           filter,
           
           faqs,
           selectedFAQ,
           isLoadingFAQ,
           isRefetchingFAQ,
           //isLoadingOrganizationSummary,
           //isRefetchingOrganizationSummary,
           token, userID, organizationID,
           isLoadingCurrentUser,
           isRefetchingCurrentUser,
           isAddingFaqAllowed,
           matchSM,
           matchMD,
           previewMode,
           apiKey,
           production,
           //summary,
           darkMode,
           poweredBy,
           primaryColor,
           secondaryColor,
           tertiaryColor,
           quaternaryColor,
           companyTheme,
           companyAIName,
           companyName,
           companyDesc,
           companyWebsite,
           companyPhone,
           companyEmail,
           companyEmailNotifications,
           companyCountryPhone,
           companyType,
           companyStatus,
           companyWebhook,
           chatPosition,
           companyAIPersonality,
           chatOpen,
           convoMode,
           isLoadingOrganization,
           showTeam,
           showFAQ,
           hideClose,
           buttonGradient,
           autoPrompt,
           autoSlack,
           collectEmail,
           collectPhone,
           companyGA,
           currentPlan,
        },
      }}
      {...props}
    >
      {children}
    </DemoContext.Provider>
  );
};
