import React, {useContext, useEffect, useMemo, useState} from 'react';
import Header from '../../Shared/Header';
import BreadCums from '../../Shared/BreadCums';
import {DemoContext} from "../../../lib/contexts/DemoContext";
import Footer3 from "../../Shared/Footer3";
import {Box, FormHelperText, Grid, Paper, Typography} from "@mui/material";
import PromptBrowser from "./PromptBrowser";
import makeStyles from "@mui/styles/makeStyles";
import {AIChat} from "../AIChat";
const useStyles = makeStyles(theme => ({
   root: {
      display:'block',
      width: '100%',
      padding: 10,
      '& nav': {
         padding: '0px !important',
         margin: '0px !important',
      },
      marginBottom:10
   },
   screen:{
      width:'100%',
      //  maxWidth:'80vw',
      margin:'auto',
   },
   container:{
      width:'100%',
//    maxWidth:'80vw',
      margin:'auto auto 40px',
      '& h2':{
         width:'100%',
         fontSize:20,
         marginBottom:20,
         color: theme.palette.primary.main,
         display: 'block'
      }
   }
}));

const PromptChat = () => {
   const classes = useStyles();
   const {
      state: { matchSM, matchMD, latestPrompt },
   } = useContext(DemoContext);
   const chatKey = useMemo(() => Math.random(), [latestPrompt]);
   return (
      <>
         <Header
            parentMenu='pages'
            headerClass='sc-header-section inner-header-style'
            signUpBtn='inner-btn'
         />
         <BreadCums pageTitle='Customize' empty />
         <section className={`${matchSM ? '' :'sc-feature-section-area5'} sc-pt-30 sc-md-pt-30 sc-pb-140 sc-md-pb-70`}>
                     <Grid
                        container
                        alignItems="center"
                        spacing={0}
                        className={classes.screen}
                     >
                        <Grid
                           container
                           item xs={12}
                           direction="row"
                           justifyContent="center"
                           alignItems="flex-start"
                           spacing={2}
                           className={classes.container}
                        >
                           <Grid item xs={matchMD ? 12 : 6}>
                           <PromptBrowser/>
                           </Grid>
                           <Grid item xs={matchMD ? 12 : 6}>
                              <Paper className={classes.root}>
                                 <Typography variant={'subtitle1'} style={{ marginTop: 20, marginBottom: 20 }}>
                                    AI Chat
                                 </Typography>
                                 <FormHelperText style={{ marginTop: 10, marginBottom: 10 }}>
                                    Directly try out Prompts with the latest GPT Models. Chat history is currently disabled.
                                 </FormHelperText>
                                 <AIChat key={chatKey} initialMessage={latestPrompt}/>
                              </Paper>
                           </Grid>
                           </Grid>
                     </Grid>
         </section>
        {/* <Footer3/>*/}
      </>
   );
};

export default PromptChat;
