
export const ENVIRONMENT = [
  // always point native to production
  ['production', window.location.origin.includes('capacitor://localhost')],
  ['development', !window.location.origin.includes('capacitor://localhost') && !window.location.origin.includes('emailsignature.app')],
  ['staging', window.location.origin.includes('staging.emailsignature.app')],
  ['production', !window.location.origin.includes('staging.emailsignature.app') && window.location.origin.includes('emailsignature.app')]
].find(([name, isActive]) => isActive)[0]
export const getSubdomainClientHost = (subdomain) =>
  ({
    development: `http://${subdomain}.localhost:3001`,
    staging: `https://${subdomain}.staging.emailsignature.app`,
    production: `https://${subdomain}.emailsignature.app`
  }[ENVIRONMENT])

export const getClientHost = () =>
  ({
    development: `http://localhost:3000`,
    staging: `https://staging.emailsignature.app`,
    production: `https://emailsignature.app`
  }[ENVIRONMENT])

export const CLIENT_HOST = {
  development: `http://localhost:3000`,
  staging: `https://beta.askthe.ai`,
  production: `https://askthe.ai`
}[ENVIRONMENT]
export const API_HOST = {
  //development: 'http://localhost:5005/v1',
  development: 'https://api.supportgpt.co/v1',
  staging: 'https://api.staging.supportgpt.co/v1',
  production: 'https://api.supportgpt.co/v1'
}[ENVIRONMENT]

export const GOOGLE_ANALYTICS_TRACKING_ID = {
  development: null,
  staging: 'UA-137832774-xx',
  production: 'UA-137832774-xx'
}[ENVIRONMENT]
export const INDUSTRY_SECTORS = [
  "Accounting",
  "Airlines/Aviation",
  "Alternative Dispute Resolution",
  "Alternative Medicine",
  "Animation",
  "Apparel & Fashion",
  "Architecture & Planning",
  "Arts & Crafts",
  "Automotive",
  "Aviation & Aerospace",
  "Banking",
  "Biotechnology",
  "Broadcast Media",
  "Building Materials",
  "Business Supplies & Equipment",
  "Capital Markets",
  "Chemicals",
  "Civic & Social Organization",
  "Civil Engineering",
  "Commercial Real Estate",
  "Computer & Network Security",
  "Computer Games",
  "Computer Hardware",
  "Computer Networking",
  "Computer Software",
  "Construction",
  "Consumer Electronics",
  "Consumer Goods",
  "Consumer Services",
  "Cosmetics",
  "Dairy",
  "Defense & Space",
  "Design",
  "Education Management",
  "E-learning",
  "Electrical & Electronic Manufacturing",
  "Entertainment",
  "Environmental Services",
  "Events Services",
  "Executive Office",
  "Facilities Services",
  "Farming",
  "Financial Services",
  "Fine Art",
  "Fishery",
  "Food & Beverages",
  "Food Production",
  "Fundraising",
  "Furniture",
  "Gambling & Casinos",
  "Glass, Ceramics & Concrete",
  "Government Administration",
  "Government Relations",
  "Graphic Design",
  "Health, Wellness & Fitness",
  "Higher Education",
  "Hospital & Health Care",
  "Hospitality",
  "Human Resources",
  "Import & Export",
  "Individual & Family Services",
  "Industrial Automation",
  "Information Services",
  "Information Technology & Services",
  "Insurance",
  "International Affairs",
  "International Trade & Development",
  "Internet",
  "Investment Banking/Venture",
  "Investment Management",
  "Judiciary",
  "Law Enforcement",
  "Law Practice",
  "Legal Services",
  "Legislative Office",
  "Leisure & Travel",
  "Libraries",
  "Logistics & Supply Chain",
  "Luxury Goods & Jewelry",
  "Machinery",
  "Management Consulting",
  "Maritime",
  "Marketing & Advertising",
  "Market Research",
  "Mechanical or Industrial Engineering",
  "Media Production",
  "Medical Device",
  "Medical Practice",
  "Mental Health Care",
  "Military",
  "Mining & Metals",
  "Motion Pictures & Film",
  "Museums & Institutions",
  "Music",
  "Nanotechnology",
  "Newspapers",
  "Nonprofit Organization Management",
  "Oil & Energy",
  "Online Publishing",
  "Outsourcing/Offshoring",
  "Package/Freight Delivery",
  "Packaging & Containers",
  "Paper & Forest Products",
  "Performing Arts",
  "Pharmaceuticals",
  "Philanthropy",
  "Photography",
  "Plastics",
  "Political Organization",
  "Primary/Secondary",
  "Printing",
  "Professional Training",
  "Program Development",
  "Public Policy",
  "Public Relations",
  "Public Safety",
  "Publishing",
  "Railroad Manufacture",
  "Ranching",
  "Real Estate",
  "Recreational",
  "Facilities & Services",
  "Religious Institutions",
  "Renewables & Environment",
  "Research",
  "Restaurants",
  "Retail",
  "Security & Investigations",
  "Semiconductors",
  "Shipbuilding",
  "Sporting Goods",
  "Sports",
  "Staffing & Recruiting",
  "Supermarkets",
  "Telecommunications",
  "Textiles",
  "Think Tanks",
  "Tobacco",
  "Translation & Localization",
  "Transportation/Trucking/Railroad",
  "Utilities",
  "Venture Capital",
  "Veterinary",
  "Warehousing",
  "Wholesale",
  "Wine & Spirits",
  "Wireless",
  "Writing & Editing"
];
export const pricingData = [
  {
    "plan": "Free",
    "price": 0,
    "annual_price": 0,
    "aiResponses": 50,
    "faq": 0,
    "aiPersonalities": 1,
    "otherFeatures": ["AI-Response Only"],
    "dev_payment_link": "https://example.com/payment/free",
    "dev_annual_payment_link": "https://example.com/payment/free/annual",
    
    "payment_link": "https://example.com/payment/free",
    "annual_payment_link": "https://example.com/payment/free/annual",
    "isVisible": false
  },
  {
    "plan": "Starter",
    "price": 19,
    "annual_price": Math.round(19 * 0.75),
    "aiResponses": 250,
    "faq": 10,
    "aiPersonalities": 3,
    "otherFeatures": ["Auto-Translation", "Basic Analytics"],
    "dev_payment_link": "https://buy.stripe.com/test_eVag1n29H2Pc5Ta000",
    "dev_annual_payment_link": "https://buy.stripe.com/test_5kA7uRbKh3TgdlC3cd",
    
    "payment_link": "https://buy.stripe.com/4gw9CufHsgJxdeo4gg",
    "annual_payment_link": "https://buy.stripe.com/4gwbKC8f0ctha2ceUV",
    "isVisible": true
  },
  {
    "plan": "Basic",
    "price": 49,
    "annual_price": Math.round(49 * 0.75),
    "aiResponses": 500,
    "faq": 50,
    "aiPersonalities": 5,
    "otherFeatures": ["Slack Integration", "Status Message", "Email Notifications"],
    "dev_payment_link": "https://buy.stripe.com/test_eVag1n29H2Pc5Ta000",
    "dev_annual_payment_link": "https://buy.stripe.com/test_5kA7uRbKh3TgdlC3cd",
  
    "payment_link": "https://buy.stripe.com/3cs01U1QC50PgqA5kn",
    "annual_payment_link": "https://buy.stripe.com/4gw3e61QC78Xfmw002",
    "isVisible": true
  },
  {
    "plan": "Growth",
    "price": 99,
    "annual_price": Math.round(99 * 0.75),
    "aiResponses": '1,000',
    "faq": 100,
    "aiPersonalities": 11,
    "otherFeatures": ["White-labeling", "Email Capture"],
    "dev_payment_link": "https://buy.stripe.com/test_eVag1n29H2Pc5Ta000",
    "dev_annual_payment_link": "https://buy.stripe.com/test_5kA7uRbKh3TgdlC3cd",
  
    "payment_link": "https://buy.stripe.com/28o3e6brc8d1eis4gk",
    "annual_payment_link": "https://buy.stripe.com/7sI8yq3YKdxl5LW6ot",
    "isVisible": true
  },
  {
    "plan": "Pro",
    "price": 199,
    "annual_price": Math.round(199 * 0.75),
    "aiResponses": '2,500',
    "faq": "Unlimited",
    "aiPersonalities": 'Custom',
    "otherFeatures": ["Phone Capture", "Webhooks", "Advanced Analytics"],
    "dev_payment_link": "https://buy.stripe.com/test_eVag1n29H2Pc5Ta000",
    "dev_annual_payment_link": "https://buy.stripe.com/test_5kA7uRbKh3TgdlC3cd",
  
    "payment_link": "https://buy.stripe.com/7sI9Cu7aW3WLb6g9AG",
    "annual_payment_link": "https://buy.stripe.com/28obKCeDogJx0rCdQX",
    "isVisible": true
  },
  {
    "plan": "Enterprise",
    "price": "$$$",
    "annual_price": "$$$",
    "aiResponses": "Unlimited",
    "faq": "Unlimited",
    "aiPersonalities": "Custom",
    "otherFeatures": ["Custom Integrations", "Priority Support", "Dedicated Account Manager"],
    "dev_payment_link": "https://buy.stripe.com/test_eVag1n29H2Pc5Ta000",
    "dev_annual_payment_link": "https://buy.stripe.com/test_5kA7uRbKh3TgdlC3cd",
  
    "payment_link": "https://example.com/payment/enterprise/monthly",
    "annual_payment_link": "https://example.com/payment/enterprise/annual",
    "isVisible": false
  }
]

export const COLORS = ['#39C091', '#fd6f5c', '#9e9ea8', '#f6f9fc']

export const FAQ_LIMIT_LABEL = 'You have reached your plan\'s FAQ limit. Please upgrade to a plan that supports more FAQs.'
export const SOMETHING_WRONG_TRY_AGAIN_LABEL = 'Something went wrong, please try again.'

export const confidentialityClauses = [
  {
    key: 'standardConfidentiality',
    text: "This email is intended only for the person to whom it is addressed and may contain confidential information. If you have received this email in error, please notify us immediately and delete it.",
    label: 'Standard Confidentiality',
    default: true
  },
  {
    key: 'ndaStatement',
    text: "This email may contain information that is subject to a non-disclosure agreement. Please do not share, copy, or distribute its contents.",
    label: 'NDA Statement',
    default: false
  },
  {
    key: 'encryptionNotice',
    text: "This email has been sent through an encrypted service for your security.",
    label: 'Encryption Notice',
    default: false
  },
  {
    key: 'ethicalWall',
    text: "This email respects all ethical guidelines and restrictions of our organization.",
    label: 'Ethical Wall Statement',
    default: false
  },
  {
    key: 'conflictOfInterest',
    text: "This email is written with no conflict of interest.",
    label: 'Conflict of Interest Statement',
    default: false
  },
  {
    key: 'regulatoryCompliance',
    text: "This email is compliant with all relevant regulations and standards.",
    label: 'Regulatory Compliance Statement',
    default: false
  },
  {
    key: 'environmentFriendly',
    text: "Please consider the environment before printing this email.",
    label: 'Environment Friendly Note',
    default: false
  },
];
