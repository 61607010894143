import React, {useCallback, useContext, useState} from 'react';
import {
   Box,
   TextField,
   List,
   ListItem,
   ListItemText,
   Toolbar,
   Typography,
   CssBaseline,
   Tab,
   Tabs,
   Paper, Button, Grid, FormHelperText, InputAdornment,
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import promptLibrary from './prompts.json'
import makeStyles from "@mui/styles/makeStyles";
import {enqueueSnackbar} from "notistack";
import {DemoContext} from "../../../lib/contexts/DemoContext";
const useStyles = makeStyles(theme => ({
   root: {
      display:'block',
      width: '100%',
      padding: 10,
      '& nav': {
         padding: '0px !important',
         margin: '0px !important',
      },
      marginBottom:10
   },
   screen:{
      width:'100%',
      //  maxWidth:'80vw',
      margin:'auto',
   },
   container:{
      width:'100%',
//    maxWidth:'80vw',
      margin:'auto auto 40px',
      '& h2':{
         width:'100%',
         fontSize:20,
         marginBottom:20,
         color: theme.palette.primary.main,
         display: 'block'
      }
   }
}));
function PromptBrowser() {
   const classes = useStyles();
   const {
      state: { matchSM },
      actions: { setLatestPrompt },
   } = useContext(DemoContext);
   
   const [searchTerm, setSearchTerm] = useState('');
   const [selectedType, setSelectedType] = useState('rolesPlaying');  // 'rolesPlaying' is the category name in your JSON
   const [selectedItem, setSelectedItem] = useState(null);
   const [tabValue, setTabValue] = useState(0);
   const categories = Object.keys(promptLibrary.promptLibrary).filter(key => key !== 'metadata');
   
   const filteredItems = promptLibrary.promptLibrary[selectedType]?.items.filter(item =>
      item.title?.toLowerCase().includes(searchTerm.toLowerCase()) ||
      item.task?.toLowerCase().includes(searchTerm.toLowerCase())
   ) || [];
   
   const handleTabChange = (event, newValue) => {
      setTabValue(newValue);
      setSelectedType(categories[newValue]);
      setSelectedItem(null);
   };
   const handleTest = useCallback(() => {
      setLatestPrompt('Execute Task: '+JSON.stringify(selectedItem))
   }  ,[selectedItem])
   
   const handleCopy = () => {
      copyTextToClipboard()
         .then(() => {
            enqueueSnackbar('Copied Prompt.', {variant:'info'})
         })
         .catch((err) => {
            enqueueSnackbar('Your browser settings have prevented automatic copying. Please copy manually.', {variant:'error'})
            //console.log(err);
         });
   }
   const copyTextToClipboard = useCallback(async () => {
      if ('clipboard' in navigator) {
         return await navigator.clipboard.writeText('Execute Task: '+JSON.stringify(selectedItem));
      } else {
         return document.execCommand('copy', true, 'Execute Task: '+JSON.stringify(selectedItem));
      }
   },[selectedItem]);
   return (
      <>
         <Paper className={classes.root}>
            <Grid container spacing={0}>
               <Grid item xs={8}>
                  <Typography variant={'subtitle1'} style={{ marginTop: 20, marginBottom: 0 }}>
                     Prompt Browser
                  </Typography>
                  <FormHelperText style={{ marginTop: 10, marginBottom: 10 }}>
                     Browse by Category & Topic.
                  </FormHelperText>
               </Grid>
               <Grid item xs={4}>
                  <TextField
                     variant="standard"
                     placeholder="Search prompts..."
                     value={searchTerm}
                     onChange={(e) => setSearchTerm(e.target.value)}
                     sx={{ ml: 0, mt:4 }}
                     InputProps={{
                        startAdornment: (
                           <InputAdornment position="start">
                              <SearchIcon />
                           </InputAdornment>
                        ),
                     }}
                  />

               </Grid>
               <Grid item xs={12}>
   
                  <Tabs
                     value={tabValue}
                     onChange={handleTabChange}
                     centered
                     variant="scrollable"
                     scrollButtons="auto">
                     {categories.map((category, index) => (
                        <Tab label={promptLibrary.promptLibrary[category].metadata.title} key={index} />
                     ))}
                  </Tabs>
                  <Box sx={{ display: 'flex' }}>
                     <CssBaseline />
                     <Grid container spacing={0}>
                        <Grid item xs={matchSM ? 6 : 4}>
                           <Box sx={{ overflow: 'auto', maxHeight:565 }}>
                              <List>
                                 {filteredItems.map((item, index) => (
                                    <ListItem
                                       button
                                       key={item.id}
                                       selected={selectedItem && selectedItem.id === item.id}
                                       onClick={() => setSelectedItem(item)}
                                    >
                                       <ListItemText primary={item.title || item.task} />
                                    </ListItem>
                                 ))}
                              </List>
                           </Box>
                        </Grid>
                        <Grid item xs={matchSM ? 6 : 8}>
                           <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
                              <Toolbar />
                              {selectedItem ? (
                                 <Paper elevation={3} sx={{ p: 3 }}>
                                    <Typography variant="h5">{selectedItem.title || selectedItem.task}</Typography>
                                    <Typography variant="body1" sx={{ mt: 2 }}>
                                       {selectedItem.description || selectedItem.instruction}
                                    </Typography>
                                    <Typography variant="body2" sx={{ mt: 2 }}>
                                       Example Response:<br/>
                                    </Typography>
                                    <Typography variant="body2" sx={{ mt: 1, fontStyle: 'italic' }}>
                                       {selectedItem.example}
                                    </Typography>
   
                                    <Grid container spacing={0} justifyContent={'space-evenly'} style={{margin:'auto'}}>
                                    <Button
                                       variant="contained"
                                       color="primary"
                                       sx={{ mt: 2 }}
                                       onClick={handleCopy}
                                    >
                                       Copy Prompt
                                    </Button>
                                    <Button
                                       variant="contained"
                                       color="primary"
                                       sx={{ mt: 2 }}
                                       onClick={handleTest}
                                    >
                                       Test Prompt
                                    </Button>
                                    </Grid>
                                 </Paper>
                              ) : (
                                 <Typography variant="body1">Select an item to view details.</Typography>
                              )}
                           </Box>
                        </Grid>
                     </Grid>
            
                  </Box>
               </Grid>
            </Grid>
         </Paper>

      </>
   );
}

export default PromptBrowser;
